import React, { useState, useEffect } from 'react';

export const handleCarouselClick = (title) => {
  const choice = localStorage.getItem('userChoice');
  
  // If the user has a saved choice, redirect automatically
  if (choice) {
    redirectTo(choice);
    return;
  }

  // Show the popup to ask the user if they want to remember their choice
  const userWantsToRemember = window.confirm(
    `Do you want to remember your choice of ${title}?`
  );

  if (userWantsToRemember) {
    // Save the user's choice in localStorage
    localStorage.setItem('userChoice', title);
  }

  // Redirect to the chosen URL
  redirectTo(title);
};

const redirectTo = (choice) => {
  switch (choice) {
    case 'Sales':
      window.location.href = 'https://sales.spearbi.co.za';
      break;
    case 'Stock':
      window.location.href = 'https://stock.spearbi.co.za';
      break;
    case 'Xpress':
      window.location.href = 'https://xpress.spearbi.co.za';
      break;
    default:
      console.error('Invalid choice:', choice);
  }
};
const handleRedirectFalse = () => {
    const params = new URLSearchParams(window.location.search);
    if (params.get('redirect') === 'false') {
      localStorage.removeItem('userChoice');
    }
  };
// Component to handle automatic redirection if user choice is remembered
export const CheckUserChoice = () => {
  useEffect(() => {
    handleRedirectFalse();

    const choice = localStorage.getItem('userChoice');
    if (choice) {
      redirectTo(choice);
    }
  }, []);

  return null;
};
