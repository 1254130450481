import * as THREE from 'three';
import { useRef, useState } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { Image, Environment, ScrollControls, useScroll, Html } from '@react-three/drei';
import { easing } from 'maath';
import './util'; // Import the utility file to extend custom objects
import { handleCarouselClick, CheckUserChoice } from './Functionality'; 

const interFontUrl = "@pmndrs/assets/fonts/inter_regular.woff"; // Ensure you use the same font

const CarouselContent = () => (
  <>
  <CheckUserChoice /> 
 
  <Canvas
    camera={{ position: [0, 0, 100], fov: 15 }}
    style={{ height: 'calc(100vh - 25vh)', width: '100%', overflow: 'hidden' }}
  >
    <ScrollControls pages={1} damping={0.1}>
      <Rig rotation={[0, 0, 0.15]}>
        <Carousel />
      </Rig>
    </ScrollControls>
    <Environment preset="dawn" background={false} blur={0.5} />
  </Canvas>
  </>
);

function Rig(props) {
  const ref = useRef();
  const scroll = useScroll();
  useFrame((state, delta) => {
    ref.current.rotation.y = -scroll.offset * (Math.PI * 2);
    state.events.update();
    easing.damp3(state.camera.position, [-state.pointer.x * 2, state.pointer.y + 1.5, 10], 0.3, delta);
    state.camera.lookAt(0, 0, 0);
  });
  return <group ref={ref} {...props} />;
}

function Carousel({ radius = 2, count = 3 }) {
  const items = ['Sales', 'Stock', 'Xpress'];
  const handleClick = (title) => {
    handleCarouselClick(title); // Use the imported function to handle the click
  };
  return items.map((title, i) => (
    <Card
      key={i}
      url={`/${title.toLowerCase()}.jpg`}
      title={title}
      onClick={() => handleClick(title)} // Pass the onClick handler
      position={[Math.sin((i / count) * Math.PI * 2) * radius, 0, Math.cos((i / count) * Math.PI * 2) * radius]}
      rotation={[0, Math.PI + (i / count) * Math.PI * 2, 0]}
      scale={[1.5, 1.5, 1.5]}
    />
  ));
}

function Card({ url, title, ...props }) {
  const ref = useRef();
  const [hovered, hover] = useState(false);
  const pointerOver = (e) => (e.stopPropagation(), hover(true));
  const pointerOut = () => hover(false);
  useFrame((state, delta) => {
    easing.damp3(ref.current.scale, hovered ? 1.8 : 1.5, 0.1, delta);
    easing.damp(ref.current.material, 'radius', hovered ? 0.25 : 0.1, 0.2, delta);
    easing.damp(ref.current.material, 'zoom', hovered ? 1 : 1.5, 0.2, delta);
  });
  return (
    <Image
      ref={ref}
      url={url}
      transparent
      side={THREE.DoubleSide}
      onPointerOver={pointerOver}
      onPointerOut={pointerOut}
      onClick={props.onClick}
      {...props}
    >
      <bentPlaneGeometry args={[0.6, 1.2, 1, 20, 20]} />
      <Html>
        <div style={{ color: 'black', fontSize: '24px', textAlign: 'center', fontFamily: 'Inter, sans-serif' }}>{title}</div> {/* Black text and Inter font */}
      </Html>
    </Image>
  );
}

export default CarouselContent;
