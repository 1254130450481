import React from "react";
import Logo3 from "./components/Logo3"; 
import CarouselContent from "./components/CarouselContent"; 

function App() {
  return (
    <div>
      <Logo3 />
      {/* Other content can go here */}
      <CarouselContent />
    </div>
  );
}

export default App; // Ensure the default export is present
