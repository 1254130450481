import React from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { useGLTF, Float, Lightformer, ContactShadows, Environment, MeshTransmissionMaterial, useTexture } from "@react-three/drei";
import { Bloom, EffectComposer, N8AO, TiltShift2 } from "@react-three/postprocessing";
import { Route } from "wouter";
import { suspend } from "suspend-react";
import { easing } from "maath";

const inter = import("@pmndrs/assets/fonts/inter_regular.woff");

const Logo2 = () => (
  <LogoContainer>
    <Canvas eventSource={document.getElementById("root")} eventPrefix="client" shadows camera={{ position: [0, 0, 20], fov: 50 }}>
      <color attach="background" args={["#e0e0e0"]} />
      <spotLight position={[10, 15, 10]} penumbra={1} castShadow angle={0.3} intensity={0.3} />
      <Float floatIntensity={2}>
        <Route path="/">
          <ImagePlane position={[0, -3, -5]} scale={[10, 10, 10]} />
          {/* Replace Knot with custom Arrow component */}
          <Spear scale={[6, 6, 6]} position={[2, -2, 0]} />
        </Route>
        <Route path="/torus">
          <Torus />
        </Route>
        <Route path="/bomb">
          <Bomb scale={0.7} />
        </Route>
      </Float>
      <ContactShadows scale={100} position={[0, -7.5, 0]} blur={1} far={100} opacity={0.85} />
      <Environment preset="city">
        <Lightformer intensity={8} position={[10, 5, 0]} scale={[10, 50, 1]} onUpdate={(self) => self.lookAt(0, 0, 0)} />
      </Environment>
      <EffectComposer disableNormalPass>
        <N8AO aoRadius={1} intensity={2} />
        <Bloom mipmapBlur luminanceThreshold={0.9} intensity={0.5} levels={8} />
        <TiltShift2 blur={0.2} />
      </EffectComposer>
      <Rig />
    </Canvas>
  </LogoContainer>
);

const LogoContainer = ({ children }) => <div style={{ height: "25vh", width: "100%", overflow: "hidden", position: "relative" }}>{children}</div>;

function ImagePlane(props) {
  const imageTexture = useTexture("/spear_logo.png"); // Load the image as a texture

  return (
    <mesh {...props}>
      <planeGeometry args={[10, 5]} />
      <meshBasicMaterial map={imageTexture} transparent />
    </mesh>
  );
}

function Rig() {
  useFrame((state, delta) => {
    easing.damp3(state.camera.position, [Math.sin(-state.pointer.x) * 5, state.pointer.y * 3.5, 15 + Math.cos(state.pointer.x) * 10], 0.2, delta);
    state.camera.lookAt(0, 0, 0);
  });
}

const Arrow = (props) => (
  <group {...props} rotation={[0, 0, -Math.PI / 2]}>
    {" "}
    {/* Rotate the arrow to point to the right */}
    {/* Longer shaft of the arrow */}
    <mesh position={[0, 0, 0]} receiveShadow castShadow>
      <cylinderGeometry args={[0.1, 0.1, 5, 32]} /> {/* Increased the height to 5 to make the shaft longer */}
      <MeshTransmissionMaterial transmission={1} thickness={2} roughness={0.05} ior={1.5} chromaticAberration={0.02} clearcoat={1} clearcoatRoughness={0} envMapIntensity={1} attenuationDistance={0.1} attenuationColor="#ffffff" />
    </mesh>
    {/* Larger arrowhead */}
    <mesh position={[0, 2.8, 0]} receiveShadow castShadow>
      {" "}
      {/* Adjusted position to move the arrowhead to the tip of the longer shaft */}
      <coneGeometry args={[0.3, 1, 32]} /> {/* Increased the height to 1 to make the arrowhead larger */}
      <MeshTransmissionMaterial transmission={1} thickness={2} roughness={0.05} ior={1.5} chromaticAberration={0.02} clearcoat={1} clearcoatRoughness={0} envMapIntensity={1} attenuationDistance={0.1} attenuationColor="#ffffff" />
    </mesh>
  </group>
);
const Spear = (props) => (
    <group {...props} rotation={[0, 0, -Math.PI / 2]}> {/* Rotate the spear to point to the right */}
      {/* Thicker Shaft of the spear */}
      <mesh position={[0, 0, 0]} receiveShadow castShadow>
        <cylinderGeometry args={[0.15, 0.15, 8, 32]} /> {/* Increased radii to make the shaft thicker */}
        <MeshTransmissionMaterial
          transmission={1}
          thickness={2}
          roughness={0.05}
          ior={1.5}
          chromaticAberration={0.02}
          clearcoat={1}
          clearcoatRoughness={0}
          envMapIntensity={1}
          attenuationDistance={0.1}
          attenuationColor="#ffffff"
        />
      </mesh>
      {/* Spearhead */}
      <mesh position={[0, 4.5, 0]} receiveShadow castShadow> {/* Position spearhead at the tip of the shaft */}
        <coneGeometry args={[0.4, 1.5, 32]} /> {/* Longer, slightly wider spearhead */}
        <MeshTransmissionMaterial
          transmission={1}
          thickness={2}
          roughness={0.05}
          ior={1.5}
          chromaticAberration={0.02}
          clearcoat={1}
          clearcoatRoughness={0}
          envMapIntensity={1}
          attenuationDistance={0.1}
          attenuationColor="#ffffff"
        />
      </mesh>
      {/* Thicker Butt of the spear */}
      <mesh position={[0, -4.5, 0]} receiveShadow castShadow> {/* Position butt at the bottom of the shaft */}
        <sphereGeometry args={[0.2, 32, 32]} /> {/* Increased radius to make the butt thicker */}
        <MeshTransmissionMaterial
          transmission={1}
          thickness={2}
          roughness={0.05}
          ior={1.5}
          chromaticAberration={0.02}
          clearcoat={1}
          clearcoatRoughness={0}
          envMapIntensity={1}
          attenuationDistance={0.1}
          attenuationColor="#ffffff"
        />
      </mesh>
    </group>
  );
  

const Torus = (props) => (
  <mesh receiveShadow castShadow {...props}>
    <torusGeometry args={[4, 1.2, 128, 64]} />
    <MeshTransmissionMaterial backside backsideThickness={5} thickness={2} />
  </mesh>
);

function Bomb(props) {
  const { nodes } = useGLTF("/bomb-gp.glb");
  return (
    <mesh receiveShadow castShadow geometry={nodes.Little_Boy_Little_Boy_Material_0.geometry} {...props}>
      <MeshTransmissionMaterial backside backsideThickness={10} thickness={5} />
    </mesh>
  );
}

export default Logo2;
